<template>
  <section class="content-container">
    <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>

<!-- <template>
  <div class="culture">
    <div class="culture-sidebar">
      <SideBar />
    </div>
    <div class="culture__container">
      <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
      <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
      <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />

      <div class="culture__body">
        <div class="culture__body-top">
          <SectionTitle :title="title" subtitle="" />
          <ProfileNav />
        </div>
        <div class="tabs">
          <SectionTabs :tabs="tab_component" :key="tabs" />
        </div>
        <router-view/>
        <footerSec></footerSec>
      </div>
    </div>
  </div>
</template> -->

<script>
import { GET_CULTURA_ID } from "@/apollo/queries";
import SectionTabs from "@/components/Section/SectionTabs.vue";
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import footerSec from "@/components/Section/SectionFooter.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue"; */

export default {
  components: {
    SectionTabs,
/*     SideBar,
    SectionTitle,
    footerSec,
    ProfileNav, */
  },
  data() {
    return {
      title: "Cultura",
      tab_component:[
              {
                name: "Lista",
                route: "culture-list",
              },
              {
                name: "Editar",
                route: "culture-add",
              },
              {
                name: "Valor",
                route: "culture-valor-add",
                
              }
            ],
      tabs:0,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
    };
  },
  mounted() {
    setInterval(() => {
      this.checkIfUserIsLogged();
    }, 100);
    this.getCultura();
  },
  methods: {
    getCultura() {
      this.$apollo
        .query({
          query: GET_CULTURA_ID,
          variables: {
            id_empresa: this.id_empresa, //id segun la empresa, cuando este ok el login
          },
        })
        .then((res) => {
          var resp = res.data.culturas;
          if (resp.id_cultura == null) {
            this.tab_component = [
              {
                name: "Lista",
                route: "culture-list",
              },
              {
                name: "Agregar",
                route: "culture-add",
              },
              {
                name: "Valor",
                route: "culture-valor-add",
                
              }
            ];
            this.tabs = this.tabs +1;
          } else {
            this.tab_component = [
              {
                name: "Lista",
                route: "culture-list",
              },
              {
                name: "Editar",
                route: "culture-add",
              },
              {
                name: "Valor",
                route: "culture-valor-add",
                
              }
            ];
            this.tabs = this.tabs +1;
          }
        })
        .catch(() => {
          console.log("");
        });
    },
  },
};
</script>